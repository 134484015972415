// 商品分类
import * as API from '@/api/index'
export default {
    /**  分类列表*/
    AppletGoodsCategoryList: params => {
        return API.POST('api/appletGoodsCategory/list', params)
    },
    /**  添加分类*/
    AppletGoodsCategoryAdd: params => {
        return API.POST('api/appletGoodsCategory/add', params)
    },
    /**  修改分类*/
    AppletGoodsCategoryUpdate: params => {
        return API.POST('api/appletGoodsCategory/update', params)
    },
    /**  移动分类*/
    moveCategory: params => {
        return API.POST('api/appletGoodsCategory/move', params)
    },


}